.dot {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  margin-right: 15px;

  animation-name: loading;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.dot:first-child {
  background-color: #a373e8;
  animation-delay: 0s;
}

.dot:nth-child(2) {
  background-color: #15a05b;
  animation-delay: 0.4s;
}

.dot:nth-child(3) {
  background-color: #ffa0f7;
  animation-delay: 0.8s;
}
.dot:nth-child(4) {
  background-color: #22abf7;
  animation-delay: 1.2s;
}
.dot:nth-child(5) {
  background-color: #ff432e;
  animation-delay: 1.6s;
  margin-right: 0px;
}

@keyframes loading {
  0% {
    transform: translateY(-40px);
  }
  25% {
    transform: translateY(4px);
  }
  30%,
  100% {
    transform: translateY(0px);
  }
}
